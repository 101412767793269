import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { GetDukanShopUseCase } from 'src/app/core/usecases/dukan-shop/get-dukan.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { AUTH_URL, REGISTER_URL, STORES_URL } from '../../../constants';
import { IS_DUKAN_ENABLED } from '../../../constants/feature-flags';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserResolutionService } from '../../../services/user-resolution.service';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { stringToUrlTree } from './shared';
import { loggedInFullySignedUpMixinResolver } from './shared/mixins/logged-in-fully-signed-up.mixin.shared.resolver';

/**
 * as a user, I can only access Dukan Page if:
 *
 * - I am logged in.
 * - I am fully signed up.
 * - I have met other internal rules for me to view the Dukan page, which are specific to
 * the Dukan
 */
export const editDukanResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  const userResolutionService = inject(UserResolutionService);
  const getFeatureFlagUsecase = inject(GetFeatureFlagUsecase);
  const getDukanShopUseCase = inject(GetDukanShopUseCase);
  return new Observable((subscriber) => {
    loggedInFullySignedUpMixinResolver({
      router,
      localStorageService,
      userRegistrationStepUtility,
      getRegisterTokenUseCase,
      userResolutionService,
      nextPath: {
        ifNotLoggedIn: AUTH_URL,
        ifNotFullySignedUp: REGISTER_URL,
      },
    }).subscribe({
      next: (verdict) => {
        if (verdict) {
          combineLatest({
            dukanFeatureFlag: getFeatureFlagUsecase.execute(IS_DUKAN_ENABLED),
            hasDukan: getDukanShopUseCase.execute(),
          }).subscribe({
            next: () => {
              subscriber.next(true);
            },
            error: () => {
              subscriber.next(stringToUrlTree(STORES_URL, router));
            },
          });
        } else {
          subscriber.next(verdict);
        }
      },
    });
  });
};
